<template>
  <div class="p-4">
    <div class="h-5 w-1/6 rounded-lg bg-blue-100/40"></div>
    <div class="flex items-start gap-4 pt-8">
      <div class="h-5 w-5 rounded-lg bg-blue-100/40"></div>
      <div class="flex-1 space-y-3">
        <div class="h-5 w-3/5 rounded-lg bg-blue-100/70"></div>
        <div class="h-5 w-4/5 rounded-lg bg-blue-100/40"></div>
        <div class="h-5 w-2/5 rounded-lg bg-blue-100/40"></div>
      </div>
    </div>
  </div>
</template>
